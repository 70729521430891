.home__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.home__horizontalLine {
  height: 1px;
  width: 60%;
  border-bottom: 1px solid rgba(128, 128, 128, 0.489);
}

.home__hero {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

.home__hero h1 {
  font-size: 3.8em;
}

.home__hero p {
  margin-top: 1em;
  z-index: 2;
}

.homeFooter__container {
  /* background-color: var(--tercary); */
  height: 60vh;
  max-width: 100%;
  margin-top: 5em;
  /* padding: 0 12%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homeFooter__container p {
  padding: 0 10%;
  z-index: 2;
}

.homeFooter__container p:first-child {
  font-size: 2.8em;
  margin-bottom: 0.5em;
}

.home__cardContainer {
  width: 80%;
  margin: 0 10%;
  margin-bottom: 1em;
}

.home__cardContainer h3 {
  margin: 2em 0;
  /* text-align: center; */
  font-size: 1.1em;
  margin-bottom: 1em;
  color: var(--primary);
  font-weight: 500;
  text-transform: uppercase;
}

.home__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.home__meImage {
  height: 24em;
  width: 24em;
  z-index: 1;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
}

.home__meImage img {
  width: auto;
  object-fit: contain;
  animation: memove 8s linear 2s infinite alternate;
  margin-right: 1.5em;
}

@keyframes memove {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* 
.home__meImage::before {
  content: attr(data-quote);
  height: 2em;
  position: absolute;
  z-index: 2;
  font-size: 1.2em;
  background: #fff;
  border: 3px dashed black;
  border-radius: 2em;
  padding: 0.4em;
  margin-top: -3em;
  margin-left: 6em;
  padding-left: 1em;
  padding-top: 1em;
  padding-right: 1em;
} */

.home__top {
  display: flex;
  flex-direction: row;
  width: 80%;
  flex-wrap: wrap-reverse;
  margin-left: 1em;
  margin-right: 1em;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 2;
}

.home__top__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 1em;
  width: 60%;
}

.home__top__text p {
  line-height: 2em;
  font-size: 1em;
  margin-top: 2em;
}

@media (max-width: 1320px) {
  .home__meImage {
    height: 15em;
  }

  .home__hero h1 {
    font-size: 2.4em;
  }

  .home__top__text p {
    line-height: unset;
    font-size: 1em;
    margin-top: 4em;
  }
}

@media (max-width: 1100px) {
  .home__top {
    margin-left: 10%;
    margin-right: 10%;
    justify-content: unset;
    width: unset;
  }
}

@media (max-width: 648px) {
  .home__hero {
    height: 80vh;
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .home__hero h1 {
    font-size: 1.8em;
  }

  .home__top__text {
    width: 100%;
  }

  .home__cardContainer h3 {
    font-size: 1.1em;
  }

  .homeFooter__container {
    margin-top: 1em;
    /* padding: 2em 8%; */
    height: 50vh;
  }

  .homeFooter__container p:first-child {
    font-size: 2em;
  }

  .home__top {
    margin-left: unset;
    margin-right: unset;
  }

  .home__top__text p span {
    display: none;
  }

  .home__top__text p,
  .home__top__text h1 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .home__top__text p {
    line-height: unset;
    font-size: 1em;
    margin-top: 4em;
  }

  .home__meImage {
    border-radius: 5px;
    margin-bottom: 1em;
    height: 15em;
  }
}
