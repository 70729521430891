.about__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 0 15%;
  color: var(--dark);
}

.about__container h5,
.about__container h3 {
  margin: 0.6em 0;
}

.about__data p {
  text-align: justify;
}

.about__container p {
  line-height: 1.8em;
  letter-spacing: 0.5px;
  margin-bottom: 1em;
}

.about__me {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 3em 0;
}

.about__meText {
  width: 40%;
}

.about__meText p {
  font-size: 2em;
  font-weight: 500;
}

.about__meImage {
  height: 35em;
  width: 40%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.about__meImage img {
  width: 100%;
  object-fit: cover;
}

.about__socialIconContainer {
  width: 100%;
  height: 4em;
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.about__socialIcon {
  width: 3em;
  margin-right: 2em;
}

.about__socialIcon img {
  width: 2.6em;
  -webkit-filter: drop-shadow(0 1px 3px var(--grey));
  filter: drop-shadow(0 1px 3px var(--grey));
}

.about_horizontalLine {
  width: 100%;
  border-bottom: 1px solid var(--grey);
  margin: 3em 0;
}

.about__funFact {
  margin-bottom: 3em;
}

.about__funFact div {
  display: flex;
  justify-content: space-between;
}

.about__funFact h3 {
  margin-bottom: 3em;
}

.about__me h5,
.about__funFact h3,
.about__story h3 {
  color: var(--primary);
}

.funFact__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 14em;
  padding-right: 1em;
}

.funFact__title p {
  font-size: 1.2em;
  font-weight: 700;
  margin: 1em 0 0.2em 0;
}

.funFact__description p {
  font-size: 0.9em;
  text-align: center;
}

.funFact__icon {
  width: 5em;
  height: 5em;
}

@media (max-width: 648px) {
  .about__container {
    font-size: 1em;
    width: 90%;
    margin: 1em;
  }

  .about__me {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  .about__meImage {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 1em;
  }

  .about__meImage img {
    width: 100%;
    object-fit: cover;
  }

  .about__meText {
    width: 100%;
  }

  .about__meText p {
    margin-top: 0.8em;
    font-size: 2em;
    line-height: 1.4em;
    font-weight: 500;
  }

  .about__funFact div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
