.projectCard__container {
  width: 32em;
  height: 34em;
  margin: 2em;
  position: relative;
  /* box-shadow: 0px 3px 5px var(--shadow); */
  border-radius: 0.2em;
  cursor: pointer;
}

.projectCard__img {
  object-fit: cover;
  width: 100%;
  height: calc(100% - 4em);
  border-radius: 0.2em;
  filter: brightness(0.9);
}

.projectCard__header {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  bottom: 0;
  width: 100%;
  height: 3em;
  /* margin: 0 7%; */
  padding-top: 1em;
  /* border-radius: 0 0 0.2em 0.2em; */
  /* text-align: center; */
  /* background-color: var(--light); */
  /* box-shadow: 0px 3px 5px var(--shadow); */
}

.projectCard__container h1 {
  font-size: 1.1em;
  font-weight: 400;
  text-transform: uppercase;
}

.projectCard__container p {
  font-size: 0.8em;
  color: var(--grey);
}

@media (max-width: 648px) {
  .projectCard__container {
    width: 32em!important;
    height: 24em;
    margin: 0em;
    margin-bottom: 2em;
    font-size: 0.8em;
  }
}
