.navbar__container {
  background-color: var(--secondary);
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 45px;
  padding-bottom: 1px;
  /* border-bottom: 1px solid var(--light); */
}

.navbar__menuIcon {
  display: none;
}

.navbar__logo {
  padding-left: 70px;
}

.navbar__logoImg {
  width: 8em;
  margin-top: -25px;
  margin-bottom: -10px;
  margin-right: 35px;
}

.navbar__menuList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 70px;
}

.navbar__menuList li {
  list-style: none;
  margin-right: 35px;
}

.navbar__contactBtn {
  border: 2px solid var(--primary);
  border-radius: 5px;
  padding: 10px 20px;
  color: var(--primary);
}

@media (max-width: 940px) {
  .navbar__container {
    padding-top: 1em;
    width: 100%;
    height: 3.5em;
  }

  .navbar__logo {
    padding-left: 1em;
  }

  .navbar__logoImg {
    width: 6em;
    margin-top: 0.8em;
    margin-bottom: 0px;
  }

  .navbar__menuList {
    flex-direction: column;
    justify-content: center;
  }

  .navbar__menuList li {
    margin: 0.5em 0;
    font-size: 2em;
  }

  .navbar__menuIcon {
    display: block;
    font-size: 2.5em;
    margin-right: 0.3em;
    text-align: center;
    width: 1em;
    transition: all 0.5 ease;
  }

  .hideNav {
    display: none;
  }

  .showNav {
    display: flex;
    position: absolute;
    top: 5.2em;
    left: 0;
    text-align: left;
    width: 100%;
    height: 80vh;
    padding-bottom: 50em;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--light);
    z-index: 99;
  }
}
