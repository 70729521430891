.blogPage__container {
  /* width: 50%; */
  /* margin: 0 25%; */
  margin: 0 10%;
  height: 100%;
}

.blogPage__header {
  margin: 3em 0 1em 0;
}

.blogPage__header p {
  color: var(--grey);
  font-size: 0.9em;
}

.blogPage__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.blogPage__navigation {
  display: flex;
  justify-content: space-between;
}

.blogPage__navigation p {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 648px) {
  .blogPage__container {
    width: 92%;
    padding: 0 4%;
    margin: 0;
    height: 100%;
  }

  .blogPage__content img {
    max-width: 90%;
    margin: 1em 5%;
  }
}
