.uxPractice__container {
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.uxPractice__title {
  width: 100%;
  height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2em 8em;
  margin-bottom: 0;
}

.uxPractice__title h1 {
  font-weight: 600;
}

.uxPractice__title h3 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1em;
}

.uxPractice__title a {
  color: var(--primary);
  text-decoration: none;
}

@media (max-width: 648px) {
  .uxPractice__container {
    width: 90%;
    margin: 0 5%;
  }

  .uxPractice__title {
    width: 100%;
    min-height: 14em;
    margin: 2em 0em;
    margin-top: 3em;
    font-size: 0.8em;
  }
}
