/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  /* --primary: #5F4B8BFF; */
  /* --primary: #253b59; */
  --primary: #000;
  --secondary: #fff;
  /* --secondary: #ffeae7; */
  --tercary: #f2f2f2;
  --light: #fff;
  --dark: #080a0d;
  --grey: #6a6a6a;
  --lightgrey: #757575;
  --shadow: rgba(68, 68, 68, 0.571);
}

body {
  color: var(--dark);
  background-color: var(--secondary);
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
  overflow-x: hidden;
  /* cursor: url("Assets/cursor.svg"), auto; */
}

html,
body {
  width: calc(100vw - (100vw - 100%));
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

.noLink {
  text-decoration: none;
  color: inherit;
}

.seeMore {
  display: flex;
  width: 13em;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  color: var(--primary);
}

/* @media (max-width: 648px) {
  .heading {
    font-size: 1.8em;
  }
} */

.noPage {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
