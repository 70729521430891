.embrace__container {
  width: 80%;
  margin: 0 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.embrace__title {
  width: 100%;
  height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2em 6em;
}

.embrace__title h1 {
  font-weight: 600;
}

.embrace__title h3 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1em;
}

.embrace__title span {
  color: var(--primary);
}

.embrace__project {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2em 6em;
  margin-top: 1em;
}

.embrace__description {
  width: 20%;
  color: var(--grey);
}

.embrace__description h3 {
  font-weight: 500;
  margin-bottom: 0.5em;
}

.embrace__images {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.embrace__images img {
  width: 20em;
  margin: 0 0 2em 2em;
  border-radius: 0.5em;
}

@media (max-width: 648px) {
  .embrace__container {
    width: 90%;
    margin: 0 5%;
  }

  .embrace__title {
    width: 100%;
    min-height: 14em;
    margin: 2em 0em;
    margin-top: 3em;
    font-size: 0.8em;
  }

  .embrace__project {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 96%;
    margin: 2%;
  }

  .embrace__description {
    width: 100%;
    margin-bottom: 1em;
  }

  .embrace__images {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .embrace__images img {
    width: 9em;
    margin: 1em;
    border-radius: 0.3em;
  }
}
