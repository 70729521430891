.footer__container {
  width: 80%;
  min-height: 5em;
  position: absolute;
  background-color: var(--tercary);
  /* margin-top: 3em; */
  margin-bottom: -1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
}

.footer__container a {
  padding-right: 2em;
  font-weight: 600;
  color: var(--grey);
}

.footer__resume {
  text-decoration: none;
  color: var(--primary) !important;
}

.footer__container button {
  background-color: var(--tercary);
  border: none;
  color: var(--grey);
  font-family: inherit;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 648px) {
  .footer__container {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }

  .footer__container button {
    margin: 2em 0;
  }
}
